import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './BlockContainerWithBackground.module.css';

export interface BlockContainerWithBackgroundProperties {
  backgroundColor?:
    | 'blue'
    | 'gray'
    | 'lightGray'
    | 'white'
    | 'lightGrayBlue'
    | 'blueLightGray'
    | 'lightGrayGray'
    | 'lightGrayWhite'
    | 'blueGray'
    | 'black'
    | string; // BD 10-05-2023: 'string' added because we had sanity codegen compatibility issues
  children: ReactNode;
  smallPaddingTop?: boolean;
  smallPaddingBottom?: boolean;
  noDesktopPaddingTopBottom?: boolean;
}

export const BlockContainerWithBackground = ({
  backgroundColor,
  children,
  smallPaddingTop,
  smallPaddingBottom,
  noDesktopPaddingTopBottom,
}: BlockContainerWithBackgroundProperties) => {
  const className = cx(styles.container, {
    [styles.blue]: backgroundColor === 'blue',
    [styles.gray]: backgroundColor === 'gray',
    [styles.lightGray]: backgroundColor === 'lightGray',
    [styles.white]: backgroundColor === 'white',
    [styles.black]: backgroundColor === 'black',
    [styles.lightGrayBlue]: backgroundColor === 'lightGrayBlue',
    [styles.blueLightGray]: backgroundColor === 'blueLightGray',
    [styles.lightGrayGray]: backgroundColor === 'lightGrayGray',
    [styles.lightGrayWhite]: backgroundColor === 'lightGrayWhite',
    [styles.blueGray]: backgroundColor === 'blueGray',
    [styles.smallPaddingTop]: smallPaddingTop,
    [styles.smallPaddingBottom]: smallPaddingBottom,
    [styles.noDesktopPaddingTopBottom]: noDesktopPaddingTopBottom,
  });

  return <div className={className}>{children}</div>;
};
