import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { captureException } from '@klokgroep/shared-components/utils/captureException';
import { ImageBadge } from '@klokgroep/shared-components/src/ImageBadge';
import { ImageHover } from '@klokgroep/shared-components/src/ImageHover/ImageHover';
import { LinkType, SanityImageType, createHref, SiteIdType } from '@klokgroep/sanity';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { SiteLabelPill } from '@klokgroep/shared-components/src/SiteLabelPill';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import { Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import cx from 'classnames';
import Link from 'next/link';
import styles from './PagePreviewItem.module.css';

interface Properties {
  category?: Sanity.Schema.CategoryTaxonomy;
  link?: LinkType;
  imageAsset?: NonNullable<SanityImageType>;
  previewText?: string;
  type?: string;
  title?: string;
  siteId?: SiteIdType;
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
}

const IMAGE_SIZES = [
  { width: 1300 / 2, minWidth: 1200, ratio: 1.75 },
  { width: 1200 / 2, minWidth: 1100, ratio: 1.75 },
  { width: 1100 / 2, minWidth: 1000, ratio: 1.75 },
  { width: 1000 / 2, minWidth: 900, ratio: 1.75 },
  { width: 900 / 2, minWidth: 800, ratio: 1.75 },
  { width: 800 / 2, minWidth: 768, ratio: 1.75 },
  { width: 768, minWidth: 700, ratio: 1.75 },
  { width: 700, minWidth: 600, ratio: 1.75 },
  { width: 600, minWidth: 500, ratio: 1.75 },
  { width: 500, minWidth: 400, ratio: 1.75 },
  { width: 400, minWidth: 300, ratio: 1.75 },
  { width: 300, minWidth: 1, ratio: 1.75 },
];

export const PagePreviewItem = ({
  category,
  link,
  imageAsset,
  previewText,
  type,
  siteId,
  title,
  backgroundColor,
}: Properties) => {
  const { locale, siteId: currentSiteId } = useSiteInfo();
  const router = useRouter();

  const titleToUse = useMemo(() => title?.replaceAll('*', '').replaceAll('--', ''), [title]);

  // TODO: use this logic (and remove all other logic) when all corporates are live: !!siteId && siteId !== currentSiteId
  const isExternalLink = useMemo(
    () => !!siteId && siteId !== currentSiteId && currentSiteId !== 'holding',
    [currentSiteId, siteId]
  );

  const itemLink = useMemo(() => {
    const queryParameterString = router.asPath.split('?')[1];
    return createHref({ ...link, locale }, captureException, siteId, queryParameterString);
  }, [link, locale, router.asPath, siteId]);

  return (
    <Link
      target={isExternalLink ? '_blank' : undefined}
      href={itemLink}
      className={cx(styles.container, { [styles.backgroudColorGrey]: backgroundColor === 'white' })}>
      <ImageHover.Container>
        <div className={styles.badgesContainer}>
          {!!siteId && <SiteLabelPill siteId={siteId} isExternal={isExternalLink} />}
          {!!category?.slug && category?.name ? (
            <ImageBadge
              label={category.name}
              href={{
                _type: category._type || 'categoryTaxonomy',
                slug: { _type: 'slug', current: category.slug.current },
              }}
            />
          ) : undefined}
        </div>

        {imageAsset ? (
          <ImageHover>
            <SourceSetSanityImage asset={imageAsset} sizes={IMAGE_SIZES} />
          </ImageHover>
        ) : undefined}
        <div className={styles.textContainer}>
          <RichText>
            {titleToUse ? (
              <h2>
                <strong>{titleToUse}</strong>
              </h2>
            ) : undefined}
            {previewText ? (
              <div className={styles.previewText}>
                {type === 'corporate-project' && <p className={styles.location}>{previewText.split(' • ')[0]}</p>}
                {type === 'corporate-project' ? (
                  previewText
                    .split(' • ')
                    .slice(1, previewText.split(' • ').length)
                    .map((text) => (
                      <p key={text}>
                        {' • '}
                        {text}
                      </p>
                    ))
                ) : (
                  <p>{textWithMaxCharacters(previewText)}</p>
                )}
              </div>
            ) : undefined}
          </RichText>
        </div>
      </ImageHover.Container>
    </Link>
  );
};

function textWithMaxCharacters(text: string, characterAmount = 140) {
  return text.length > characterAmount ? <Fragment>{text.slice(0, characterAmount)}&hellip;</Fragment> : text;
}
