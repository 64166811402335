import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './FlexColumn.module.css';

interface Properties {
  alignCenter?: boolean;
  children: ReactNode;
  reversed?: boolean;
}

export const FlexColumn = ({ alignCenter, children, reversed }: Properties) => {
  const className = cx(styles.container, { [styles.reversed]: reversed, [styles.alignCenter]: alignCenter });
  return <div className={className}>{children}</div>;
};
