import { GetBlogPostsResponse } from '@klokgroep/sanity';
import { RelatedContentBlockProperties } from '@klokgroep/sanity/src/queries/blocks/relatedContent';

export const getFilteredRelatedItems = <T>(
  _id: string,
  related: GetBlogPostsResponse[] | RelatedContentBlockProperties['items'] | undefined
) =>
  (related as GetBlogPostsResponse[])
    ?.filter(
      (item, index, self) => index === self?.findIndex((t) => t?._id === item?._id) && !!item?.link && item?._id !== _id
    )
    ?.slice(0, 3) as T;
