import { FigureWithCaption } from '@klokgroep/shared-components/src/FigureWithCaption';
import { SanityImageType } from '@klokgroep/sanity';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';

interface Properties {
  image: SanityImageType;
  sizes?: { width: number; minWidth: number; ratio?: number }[];
}

export const IMAGE_WITH_CAPTION_SIZES = [
  { width: 768, minWidth: 700, ratio: 16 / 9 },
  { width: 700, minWidth: 600, ratio: 16 / 9 },
  { width: 600, minWidth: 500, ratio: 16 / 9 },
  { width: 500, minWidth: 400, ratio: 16 / 9 },
  { width: 400, minWidth: 300, ratio: 16 / 9 },
  { width: 300, minWidth: 1, ratio: 16 / 9 },
];

export const ImageWithCaption = ({ image, sizes = IMAGE_WITH_CAPTION_SIZES }: Properties) => {
  if (image.caption) {
    return (
      <FigureWithCaption caption={image?.caption}>
        <SourceSetSanityImage asset={image} sizes={sizes} />
      </FigureWithCaption>
    );
  }

  return <SourceSetSanityImage asset={image} sizes={sizes} />;
};
