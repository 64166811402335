import { VideoOrAutoplayVideo } from '@klokgroep/shared-components/src/Video';
import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { BLOCK_WITH_CAROUSEL_SLIDE_SIZES, BlockWithCarousel } from '@klokgroep/shared-components/src/BlockWithCarousel';
import { IMAGE_WITH_CAPTION_SIZES, ImageWithCaption } from '@klokgroep/shared-components/src/ImageWithCaption';
import { MaxWidth } from '@klokgroep/shared-components/src//MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { VerticalMargin } from '@klokgroep/shared-components/src/VerticalMargin';
import { Fragment, useMemo } from 'react';
import styles from './MediaBlock.module.css';

interface Properties extends Sanity.Schema.MediaBlock {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  noMarginOverflow?: boolean;
}

const ComponentWithContent = ({
  title,
  description,
}: {
  title?: Properties['title'];
  description?: Properties['description'];
}) => (
  <Fragment>
    {!!title && (
      <RichText>
        <h2>{title}</h2>
      </RichText>
    )}
    {!description && <VerticalMargin />}
    {!!description && (
      <div className={styles.description}>
        <PortableTextWithLinks content={description} />
      </div>
    )}
  </Fragment>
);

interface ComponentWithCarouselProperties {
  items?: Properties['items'];
  fullWidth?: boolean;
  heightSize?: Sanity.Schema.MediaBlock['heightSize'];
}
const ComponentWithCarousel = ({ items, fullWidth, heightSize }: ComponentWithCarouselProperties) => {
  const blockWithCarouselSizes = useMemo(
    () =>
      heightSize === 'small'
        ? BLOCK_WITH_CAROUSEL_SLIDE_SIZES.map((item) => ({ ...item, ratio: item.ratio === 1 ? 1 : 16 / 7 }))
        : undefined,
    [heightSize]
  );

  const imageWithCaptionSizes = useMemo(
    () => (heightSize === 'small' ? IMAGE_WITH_CAPTION_SIZES.map((item) => ({ ...item, ratio: 16 / 7 })) : undefined),
    [heightSize]
  );

  return items && !!items[0] ? (
    items?.length > 1 ? (
      <BlockWithCarousel
        sizes={blockWithCarouselSizes}
        maxWidthSize={fullWidth ? 'fullWidth' : undefined}
        withoutContainer
        items={items}
      />
    ) : items[0]._type === 'video' ? (
      <VideoOrAutoplayVideo fullWidth={fullWidth} item={items[0]} />
    ) : (
      <ImageWithCaption sizes={imageWithCaptionSizes} image={items[0]} />
    )
  ) : undefined;
};

export const MediaBlock = ({
  items = [],
  backgroundColor,
  title,
  description,
  fullWidth,
  heightSize,
  noMarginOverflow,
}: Properties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor} smallPaddingTop={!!title || !!description}>
    {fullWidth ? (
      <Fragment>
        <MaxWidth>
          <ComponentWithContent title={title} description={description} />
        </MaxWidth>
        <ComponentWithCarousel heightSize={heightSize} fullWidth={fullWidth} items={items} />
      </Fragment>
    ) : (
      <MaxWidth noMarginOverflow={noMarginOverflow}>
        <ComponentWithContent title={title} description={description} />
        <ComponentWithCarousel heightSize={heightSize} fullWidth={fullWidth} items={items} />
      </MaxWidth>
    )}
  </BlockContainerWithBackground>
);
