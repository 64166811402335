import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { ReactNode } from 'react';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import cx from 'classnames';
import styles from './ItemsInGridWithTitle.module.css';

interface Properties {
  parentBackgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  children: ReactNode;
  title?: string;
}

export const ItemsInGridWithTitle = ({ children, title, parentBackgroundColor = 'gray' }: Properties) => {
  const { siteId } = useSiteInfo();

  return (
    <MaxWidth>
      <div className={styles.container}>
        {title ? (
          <RichText>
            <h2
              className={cx({
                [styles.alignTitleCenter]: siteId !== 'heilijgers',
                [styles.titleLight]: parentBackgroundColor === 'blue' || parentBackgroundColor === 'black',
              })}>
              {title}
            </h2>
          </RichText>
        ) : undefined}
        <div className={styles.grid}>{children}</div>
      </div>
    </MaxWidth>
  );
};
