import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './ImageHover.module.css';

interface Properties {
  children: ReactNode;
  classNames?: string[];
}

export const ImageHover = ({ children, classNames }: Properties) => (
  <div className={cx(styles.imageHover, classNames)}>{children}</div>
);

const ImageHoverContainer = ({ children }: { children: ReactNode }) => (
  <div className={styles.container}>{children}</div>
);

ImageHover.Container = ImageHoverContainer;
