import { createHrefForTypeAndSlug } from '@klokgroep/sanity';
import { useRouter } from 'next/router';
import React, { MouseEventHandler, useCallback } from 'react';
import styles from './ImageBage.module.css';
import cx from 'classnames';
import { captureException } from '@klokgroep/shared-components/utils/captureException';

interface ImageBadgeProperties {
  label?: string;
  href?: {
    _type: Sanity.Schema.Document['_type'];
    slug: {
      _type: 'slug' | 'categorySlug';
      current: string;
    };
  };
}

export const ImageBadge = ({ href, label }: ImageBadgeProperties) => {
  const router = useRouter();
  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      router.push(createHrefForTypeAndSlug({ _type: href?._type, slug: href?.slug }, captureException));
    },
    [href?._type, href?.slug, router]
  );

  return (
    <button type="button" onClick={onClick} className={cx(styles.imageBadge, { [styles.href]: !!href })}>
      {label}
    </button>
  );
};
