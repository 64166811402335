import { ReactNode } from 'react';
import styles from './VerticalMargin.module.css';

const classNames = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
  extralarge: styles.extralarge,
};

interface Properties {
  children?: ReactNode;
  size?: keyof typeof classNames;
}

export const VerticalMargin = ({ children, size = 'large' }: Properties) => {
  const className = size ? classNames[size] : undefined;
  return <div className={className}>{children}</div>;
};
