import {
  BlockContainerWithBackground,
  BlockContainerWithBackgroundProperties,
} from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { SanityRichTextType } from '@klokgroep/sanity';

export interface BlockWithImageRichTextProperties {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  content?: SanityRichTextType;
  noMarginOverflow?: boolean;
}

export const BlockWithRichText = ({ backgroundColor, content, noMarginOverflow }: BlockWithImageRichTextProperties) => (
  <BlockContainerWithBackground backgroundColor={backgroundColor} smallPaddingBottom>
    <MaxWidth size="medium" noMarginOverflow={noMarginOverflow}>
      <RichText large>{content ? <PortableTextWithLinks content={content} /> : undefined}</RichText>
    </MaxWidth>
  </BlockContainerWithBackground>
);
