import { ReactNode } from 'react';
import styles from './FigureWithCaption.module.css';

interface Properties {
  caption: string;
  children: ReactNode;
}

export const FigureWithCaption = ({ caption, children }: Properties) => (
  <figure className={styles.figure}>
    {children}
    <figcaption className={styles.caption}>{caption}</figcaption>
  </figure>
);
